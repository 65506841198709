import { Field, Form, Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import Modal from "react-modal";
import SearchIcon from "../../../../assets/images-svg/SearchIcon";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { axiosService } from "../../../../services/axios.service";
import { ALL_EMPLOYEES_URL } from "../../constants/all-employees-urls";
import "../all-employee-bankInfo/AllEmployeeBankInfo.css";
import CancelModal from "../cancel-modal/CancelModal";

Modal.setAppElement("#root");

function AllEmployeeBankInfo({ onSubmit, type, values, setActiveTab }) {
  const [modalOpen, setModalOpen] = useState(false);
  const navigate = useNavigate();
  const containerRef = useRef();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showTable, setShowTable] = useState(false);
  const [fieldsAreReadOnly, setFieldsAreReadOnly] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  const handleBankSelection = (bank) => {
    setAddIfscCode({
      ...addifscCode,
      ifscCode: ifscCode,
      bankName: bank.bankName,
      branch: bank.branch,
    });
    setBankName(bank.bankName);
    setBranch(bank.branch);
    handleClose();
  };

  const [ifscCode, setIfscCode] = useState(
    values?.ifscCode ? values?.ifscCode : ""
  );

  const [addifscCode, setAddIfscCode] = useState("");

  const [bankName, setBankName] = useState(
    values?.bankName ? values?.bankName : ""
  );
  const [branch, setBranch] = useState(values?.branch ? values?.branch : "");
  const [ifscData, setIfscData] = useState({});
  const datas = [ifscData];

  const handleifscreset = () => {
    setIfscData({
      ifscCode: "",
      bankName: "",
      branch: "",
      bankAddress: "",
    });
  };

  const handleClose = () => {
    handleifscreset(true);
    setIsModalOpen(false);
  };

  const handleVerify = async () => {
    try {
      const response = await axiosService.get(
        `${ALL_EMPLOYEES_URL.GET_IFSC_CODE}/${ifscCode}`
      );

      const ifscData = response.data.data;
      setIfscData(ifscData);
      setAddIfscCode({
        ...addifscCode,
        bankName: ifscData.bankName,
        branch: ifscData.branch,
      });

      setTimeout(() => { }, 9000);
      setShowTable(true);
    } catch (error) {
      console.error("API Error:", error);
      if (error.response) {
        console.error("API Error Response:", error.response.data);
        toast.error(
          error.response.data.message ||
          "Ensure every field is correctly filled in to continue"
        );
      }
    }
  };

  useEffect(() => {
    if (type === "View") {
      setFieldsAreReadOnly(true);
    }
  }, [addifscCode, type]);

  const [errors, setErrors] = useState({});

  const validateFields = (values) => {
    const errors = {};
    if (!values.panNumber) {
      errors.panNumber = "Enter PAN Number";
    } else if (!/^[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}$/.test(values.panNumber)) {
      errors.panNumber = "Invalid PAN Number";
    }

    if (!values.aadhaarNumber) {
      errors.aadhaarNumber = "Enter Aaadhaar Number";
    } else if (!/^[0-9]{12}$/.test(values.aadhaarNumber)) {
      errors.aadhaarNumber = "Invalid Aadhaar number";
    }
    if (!values.accountNumber) {
      errors.accountNumber = "Enter Account number";
    } else if (!/^[0-9]{5,}$/.test(values.accountNumber)) {
      errors.accountNumber = "Invalid Account number";
    }
    if (!values.ifscCode) {
      errors.ifscCode = "Enter IFSC Code";
    }

    if (!values.bankName) {
      errors.bankName = "Enter Bank Name";
    }
    if (!values.branch) {
      errors.branch = "Enter Branch";
    }
    return errors;
  };
  const handleNumericInputChange = (fieldName, e, setFieldValue) => {
    const value = e.target.value;
    const numericValue = value.replace(/\D/g, "");
    setFieldValue(fieldName, numericValue);
  };

  function convertDateFormat(dateString) {
    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
      return "";
    }
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getUTCHours()).padStart(2, "0");
    const minutes = String(date.getUTCMinutes()).padStart(2, "0");
    const seconds = String(date.getUTCSeconds()).padStart(2, "0");
    const isoDateString = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.000Z`;
    return isoDateString;
  }
  const OnEditSubmit = async (values) => {
    let mergedValues;
    try {
      mergedValues = {
        ...values,
        supervisor: { empId: values?.supervisor?.empId },
        employeeDob: convertDateFormat(values?.employeeDob),
        joiningDate: convertDateFormat(values?.joiningDate),
        lastWorkingDate: convertDateFormat(values?.lastWorkingDate),
      };
      const response = await axiosService.put(
        `${ALL_EMPLOYEES_URL.UPDATE_EMPLOYEE_DATA}`,
        mergedValues
      );

      if (response.status === 200) {
        navigate("/all-employees/employees-list");
        toast.success("Updated successfully");
      }
    } catch (error) {
      if (error?.response?.data?.message !== "Bad credentials") {
        toast.error(error?.response?.data?.message?.split(":")[1]);
      }
    }
  };
  const handleSubmit = (values) => {
    const saveValues = {
      ...values,

      ifscCode: ifscCode,
      bankName: bankName,
      branch: branch,
    };
    const validationErrors = validateFields(saveValues);
    console.log(saveValues, "ValidityState", type)
    if (type === "View") {
      onSubmit(saveValues, true);
    } else if (type === "Edit") {
      if (Object.keys(validationErrors).length > 0) {
        setErrors(validationErrors);
      } else {
        OnEditSubmit(saveValues, true);
      }
    } else {
      if (Object.keys(validationErrors).length > 0) {
        setErrors(validationErrors);
      } else {
        onSubmit(saveValues, true);
      }
    }
  };

  return (
    <div className="employee-list-container w-100  " ref={containerRef}>
      <div className="employee-list-banner ">
        <div
          className="employee-list-table-holder pt-3 "
          style={{
            marginTop: "0",
            borderTop: "0",
            borderRadius: "0px 0px 10px 10px",
          }}
        >
          <div className="Empoyee-profile-container w-100">
            <div className="addEmployee-details w-100">
              <Formik
                onSubmit={handleSubmit}
                enableReinitialize={true}
                initialValues={values}
              >
                {({ setFieldValue, values }) => (
                  <Form>
                    <div
                      className="row add-bank-container "
                      style={{ marginTop: "-5px" }}
                    >
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="add-Employee-form-item add-Employee-form-item--text w-100">
                          <Field
                            id=""
                            name="panNumber"
                            render={({ field, form, setFieldValue }) => (
                              <input
                                {...field}
                                className="add-Employee-input add-Employee-input--text"
                                type="text"
                                placeholder=""
                                readOnly={fieldsAreReadOnly}
                                maxLength={10}
                                style={{
                                  backgroundColor: values?.panNumber
                                    ? "white"
                                    : "rgba(0,0,0,0.05)",
                                  border: values?.panNumber
                                    ? "1px solid rgba(0, 0, 0, 0.5) "
                                    : "none",
                                }}
                                value={field.value ? field.value : ""}
                                onChange={(e) => {
                                  const inputValue = e.target.value || "";
                                  const upperCaseValue =
                                    inputValue.toUpperCase();
                                  form.setFieldValue(
                                    "panNumber",
                                    upperCaseValue
                                  );
                                }}
                              />
                            )}
                          />

                          <label className="add-Employee-label">PAN</label>
                          {errors.panNumber &&
                            !/^[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}$/.test(
                              values.panNumber
                            ) && (
                              <span className="error-message-text">
                                {errors.panNumber}
                              </span>
                            )}
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="add-Employee-form-item add-Employee-form-item--text w-100">
                          <Field
                            id=""
                            className="add-Employee-input add-Employee-input--text"
                            name="aadhaarNumber"
                            type="text"
                            placeholder=""
                            readOnly={fieldsAreReadOnly}
                            maxLength={12}
                            style={{
                              backgroundColor: values?.aadhaarNumber
                                ? "white"
                                : "rgba(0,0,0,0.05)",
                              border: values?.aadhaarNumber
                                ? "1px solid rgba(0, 0, 0, 0.5)"
                                : "none",
                            }}
                            onChange={(e) =>
                              handleNumericInputChange(
                                "aadhaarNumber",
                                e,
                                setFieldValue
                              )
                            }
                          />
                          <label className="add-Employee-label">AADHAR</label>
                          {errors.aadhaarNumber &&
                            !/^[0-9]{12}$/.test(values.aadhaarNumber) && (
                              <span className="error-message-text">
                                {errors.aadhaarNumber}
                              </span>
                            )}
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="add-Employee-form-item add-Employee-form-item--text w-100">
                          <Field
                            id=""
                            className="add-Employee-input add-Employee-input--text"
                            name="accountNumber"
                            type="text"
                            placeholder=""
                            readOnly={fieldsAreReadOnly}
                            maxLength={20}
                            onChange={(e) =>
                              handleNumericInputChange(
                                "accountNumber",
                                e,
                                setFieldValue
                              )
                            }
                            style={{
                              backgroundColor: values?.accountNumber
                                ? "white"
                                : "rgba(0,0,0,0.05)",
                              border: values?.accountNumber
                                ? "1px solid rgba(0, 0, 0, 0.5) "
                                : "none",
                            }}
                          />
                          <label className="add-Employee-label">
                            Account Number
                          </label>
                          {errors.accountNumber &&
                            !/^[0-9]{5,}$/.test(values.accountNumber) && (
                              <span className="error-message-text">
                                {errors.accountNumber}
                              </span>
                            )}
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-12 position-relative">
                        <div className="add-Employee-form-item add-Employee-form-item--text w-100">
                          <Field
                            name="ifscCode"
                            className="add-Employee-input add-Employee-input--text"
                            type="text"
                            placeholder=""
                            value={ifscCode}
                            onChange={(e) => setIfscCode(e.target.value)}
                            readOnly={fieldsAreReadOnly}
                            onClick={fieldsAreReadOnly ? undefined : openModal}
                            style={{
                              backgroundColor: ifscCode
                                ? "white"
                                : "rgba(0,0,0,0.05)",
                              border: ifscCode
                                ? "1px solid rgba(0, 0, 0, 0.5) "
                                : "none",
                            }}
                          />

                          <label className="add-Employee-label">
                            IFSC Code
                          </label>
                          <div
                            className="search-icon-container "
                            onClick={fieldsAreReadOnly ? undefined : openModal}
                          >
                            <SearchIcon />
                          </div>
                          {ifscCode === "" && (
                            <span className="error-message-text">
                              {errors.ifscCode}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="add-Employee-form-item add-Employee-form-item--text w-100">
                          <Field
                            id=""
                            className="add-Employee-input add-Employee-input--text"
                            name="bankName"
                            placeholder=""
                            value={bankName}
                            readOnly
                            style={{
                              backgroundColor: bankName
                                ? "white"
                                : "rgba(0,0,0,0.05)",
                              border: bankName
                                ? "1px solid rgba(0, 0, 0, 0.5) "
                                : "none",
                            }}
                          />
                          <label className="add-Employee-label">
                            Bank Name
                          </label>
                          {bankName === "" && (
                            <span className="error-message-text">
                              {errors.bankName}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="add-Employee-form-item add-Employee-form-item--text w-100">
                          <Field
                            id=""
                            className="add-Employee-input add-Employee-input--text"
                            name="branch"
                            placeholder=""
                            value={branch}
                            readOnly
                            style={{
                              backgroundColor: branch
                                ? "white"
                                : "rgba(0,0,0,0.05)",
                              border: branch
                                ? "1px solid rgba(0, 0, 0, 0.5) "
                                : "none",
                            }}
                          />
                          <label className="add-Employee-label">
                            Branch Name
                          </label>
                          {branch === "" && (
                            <span className="error-message-text">
                              {errors.branch}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                    <div
                      className="add-Employeepage-buttons"
                      style={{ textAlign: "right" }}
                    >
                      {type !== "View" && (
                        <button
                          className="Cancel-btn"
                          type="button"
                          onClick={() => setModalOpen(true)}
                        >
                          Cancel
                        </button>
                      )}
                      {type === "View" && (
                        <button
                          className="Cancel-btn"
                          type="button"
                          onClick={() =>
                            navigate("/all-employees/employees-list")
                          }
                        >
                          Cancel
                        </button>
                      )}
                      {modalOpen && (
                        <CancelModal
                          modalOpen={modalOpen}
                          closeModal={() => setModalOpen(false)}
                          onSubmit={() =>
                            navigate("/all-employees/employees-list")
                          }
                          cancelButton={true}
                          submitButton={true}
                          onCloseTitle={"Cancel"}
                        />
                      )}
                      {type === "Add" || (type !== "View" && type !== "Edit") ? (
                        <button
                          className="add-button-prev"
                          type="button"
                          onClick={() => setActiveTab("Personalinfo")}
                        >
                          Previous
                        </button>
                      ) : null}
                      {type === "Add" && (
                        <button className="add-button-next" type="submit">
                          Next
                        </button>
                      )}
                      {type === "Edit" && (
                        <button className="add-button-next" type="submit" >
                          Update
                        </button>
                      )}
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="Verify IFSC Code"
        className="ifscModal pb-0"
      >
        <div
          className="employee-list-container"
          style={{ marginTop: "-10px" }}
          ref={containerRef}
        >
          <div className="employee-list-banner">
            <div className="border-bottom search-ifsc-text">Search IFSC</div>
            <Formik initialValues={{ ifscCode: ifscCode }}>
              <Form>
                <div className="row w-100">
                  <div className="col">
                    <div className="add-Employee-form-item add-Employee-form-item--text w-100 mt-5 mb-3 d-flex">
                      <Field
                        name="ifscCode"
                        className="add-Employee-input add-Employee-input--text"
                        type=""
                        placeholder=""
                        value={ifscCode}
                        onChange={(e) => setIfscCode(e.target.value)}
                        maxLength={11}
                      />
                      <label
                        className="add-Employee-label"
                        style={{ zIndex: "000" }}
                      >
                        Enter IFSC{" "}
                      </label>

                      <button
                        onClick={handleVerify}
                        className="add-button mb-3 ms-2"
                      >
                        Verify
                      </button>
                    </div>
                  </div>
                </div>
              </Form>
            </Formik>
            {showTable && (
              <div className="">
                {datas.map((item) => (
                  <table border={1}>
                    <tbody key={item.bankId}>
                      <tr className="bank-row">
                        <th className="bank-head">IFSC Code</th>
                        <td className="bank-value">{ifscCode}</td>
                      </tr>
                      <tr className="bank-row">
                        <th className="bank-head">Bank Name</th>
                        <td className="bank-value">{item.bankName}</td>
                      </tr>
                      <tr className="bank-row">
                        <th className="bank-head">Branch Name</th>
                        <td className="bank-value">{item.branch}</td>
                      </tr>
                      <tr className="bank-row">
                        <th className="bank-head">Address</th>
                        <td className="bank-value">{item.bankAddress}</td>
                      </tr>
                    </tbody>
                  </table>
                ))}
                <div
                  className="add-Employeepage-buttons mt-3"
                  style={{ textAlign: "center" }}
                >
                  <button className="Cancel-btn" onClick={handleClose}>
                    Cancel
                  </button>
                  <button
                    className="add-button ms-1 mb-3"
                    style={{ padding: "8px", width: "90px", height: "45px" }}
                    onClick={() => handleBankSelection(ifscData)}
                  >
                    Confirm
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default AllEmployeeBankInfo;
