import React, { useState, useEffect, useRef } from "react";
import "../../all-employees/components/all-employee-navbar/AllEmployeeNavbar";
import ReporteesAttendance from "../components/all-reportees-tabs/ReporteesAttendance";
import ReporteesAppliedLeaves from "../components/all-reportees-tabs/ReporteesAppliedLeaves";
import ReporteesTimeSheet from "../components/all-reportees-tabs/ReporteesTimeSheet";
import ReporteesLeaveBalance from "../components/all-reportees-tabs/ReporteesLeaveBalance";
import "./ReporteesNavbar.css";
import { useLocation } from "react-router";
import { axiosService } from "../../../services/axios.service";
import { REPORTEES_URL } from "../constants/reportees-urls";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


const ReporteesNavbar = () => {
  const [activeTab, setActiveTab] = useState("ReporteesAttendance");
  const [isNavbarCollapsed, setNavbarCollapsed] = useState(true);
  const containerRef = useRef();
  const location = useLocation();

  useEffect(() => {
    const navbar = document.getElementById("navbarSupportedContent");
    navbar.addEventListener("hidden.bs.collapse", () => {
      setNavbarCollapsed(true);
    });
    navbar.addEventListener("shown.bs.collapse", () => {
      setNavbarCollapsed(false);
    });
    if (location.state?.activeTab) {
      setActiveTab(location.state.activeTab);
    }
  }, [location.state]);


  // const onSubmit = async () => {
  //   try {
  //     const response = await axiosService.post(`${REPORTEES_URL.GET_MONTHLY_LEAVE}`,);
  //     console.log("Leave balances updated successfully:", response.data);

  //     if (response && response.status === 200) {
  //       toast.success(response.data.data);
  //       console.log(response.data, "success success")
  //     } else {
  //       toast.error(response.data.message);
  //       console.log(response.data, "error error")
  //     }
  //   } catch (error) {
  //     console.log("Error updating leave balances:", error?.response?.data?.message);
  //     toast.error(error?.response?.data?.message);
  //   }

  // };

  return (
    <div className="mb-0 pb-0 reportees-navbar">
      <div
        className="reportees-list-container sticky-header  "
        ref={containerRef}
        style={{ zIndex: "1" }}
      >
        <div className="">
          <div
            className=" look-up-list-table-holder"
            style={{
              borderBottom: "0",
            }}
          >
            <div className="reportees-navibar-container w-100">
              <nav
                className="navbar navbar-expand-md navbar-light m-2 p-0"
                style={{ background: "white", height: "fit-content" }}
              >
                <div
                  className="container-fluid g-0  mb-2 pt-3"
                  style={{ height: "fit-content", width: "96%" }}
                >
                  <button
                    className="navbar-toggler"
                    type="button"
                    onClick={() => setNavbarCollapsed(!isNavbarCollapsed)}
                  >
                    <span className="navbar-toggler-icon"></span>
                  </button>
                  <div
                    className={`border-bottom collapse navbar-collapse ${isNavbarCollapsed ? "" : " show"
                      }`}
                    id="navbarSupportedContent"
                  >
                    <ul className="add-employee-nav gap-md-3 w-100 ps-0 d-md-flex flex-wrap">
                      <span
                        className={`reportees-navbar-text ${activeTab === "ReporteesAttendance" && "active"
                          }`}
                        onClick={() => setActiveTab("ReporteesAttendance")}
                      >
                        Attendance
                      </span>
                      <span
                        className={`reportees-navbar-text ${activeTab === "AppliedLeaves" && "active"
                          }`}
                        onClick={() => setActiveTab("AppliedLeaves")}
                      >
                        Applied Leaves
                      </span>
                      <span
                        className={`reportees-navbar-text ${activeTab === "TimeSheet" && "active"
                          }`}
                        onClick={() => setActiveTab("TimeSheet")}
                      >
                        TimeSheet
                      </span>
                      <span
                        className={`reportees-navbar-text ${activeTab === "LeaveBalance" && "active"
                          }`}
                        onClick={() => setActiveTab("LeaveBalance")}
                      >
                        Leave Balance
                      </span>
                    </ul>
                    
                  </div>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <div className="faculty-all-student-navbar-inner-container reportees-tab-details mt-4">
        {activeTab === "ReporteesAttendance" && <ReporteesAttendance />}
        {activeTab === "AppliedLeaves" && <ReporteesAppliedLeaves />}
        {activeTab === "TimeSheet" && <ReporteesTimeSheet />}
        {activeTab === "LeaveBalance" && <ReporteesLeaveBalance />}
      </div>
    </div>
  );
};

export default ReporteesNavbar;
