import React, { useState, useEffect, useRef } from "react";
import "./MyDashboard.css";
import Clock from "../../../../assets/images-svg/mydashboard/clock.svg";
import ClockIn from "../../../../assets/images-svg/ClockIn";
import ClockOut from "../../../../assets/images-svg/ClockOut";
import Chart from "react-apexcharts";
import { axiosService } from "../../../../services/axios.service";
import { DASHBOARD_URL } from "../../constants/dashboard-urls";
import sessionService from "../../../../services/sessionstorage.service";
import { useNavigate } from "react-router";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useFormik } from "formik";
import moment from "moment";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import "primereact/resources/themes/lara-light-cyan/theme.css";
import "react-bootstrap-typeahead/css/Typeahead.css";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as Yup from "yup";
import Vector from "../../../../assets/images-svg/Vector";
import { LEAVE_URL } from "../../../leaves/constants/leave-urls";
import Modal from "react-modal";
import Webcam from "react-webcam";
import { ATTENDANCE_URL } from "../../../attendance/constants/attendance-urls";

const customStyles = {
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  content: {
    top: "50%",
    left: "50%",
    width: "550px",
    height: "470px",
    transform: "translate(-50%, -50%)",
    borderRadius: "20px",
    padding: "0",
  },
};

const MyDashboard = () => {
  const navigate = useNavigate();
  const [currentTime, setCurrentTime] = useState(new Date());
  const [clockInTime, setClockInTime] = useState(null);
  const [clockOutTime, setClockOutTime] = useState(null);
  const [isClockedIn, setIsClockedIn] = useState(false);
  const [duration, setDuration] = useState(null);
  const [timer, setTimer] = useState(0);

  const [attendanceData, setAttendanceData] = useState([]);
  const User = sessionService.getUserData("userData");
  const empId = User?.employee?.empId;
  const [dashboardData, setDashboardData] = useState(null);
  const [series, setSeries] = useState([]);

  const [leaveBalances, setLeaveBalances] = useState({
    SICK_LEAVE: 0,
    PERSONAL_LEAVE: 0,
    TWO_HOUR_PERMISSION: 0,
    WFH: 0,
  });
  const [appComments, setAppComments] = useState("");

  const [viewDialog, setViewDialog] = useState(false);

  const [balanceType, setBalanceType] = useState("");
  const [inputFocused, setInputFocused] = useState(false);
  const [statusFilter, setStatusFilter] = useState(null);
  const [InitialValues, setInitialValues] = useState({
    dates: [],
    duration: "",
    isHalfDay: false,
    comments: "",
  });
  const [leaveId, setLeaveId] = useState();
  const [dates, setDates] = useState(null);
  const [showStartDayInput, setShowStartDayInput] = useState(false);
  const [startDateWithDifference, setStartDateWithDifference] = useState(0);

  // webcam

  const webcamRef = useRef(null);
  const [imgSrcIn, setImgSrcIn] = useState(null);
  const [imgSrcOut, setImgSrcOut] = useState(null);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [clockOutModalIsOpen, setClockOutModalIsOpen] = useState(false);
  const [attendanceId, setAttendanceId] = useState(null);
  const [checkOut, setCheckOut] = useState(false);
  console.log(checkOut, "666666666666666666666666666666")
  console.log(attendanceId, "yyyyyyyyyyyyyyyyyyyyyyyyyy");
  console.log(dashboardData, "eeeeeeeeeeeeeeeeeeeeeeeeeeee");
  const fetchDashboardData = async (empId) => {
    try {
      const response = await axiosService.get(
        `${DASHBOARD_URL.GET_DASHBOARD_MYDASHBOARD_URL}`,
        { empId }
      );
      setDashboardData(response?.data);

      setAttendanceId(response?.data?.attendanceId);
      updateChartData(response?.data?.last15DaysDuration);
      // console.log(response.data, "Data fetched successfully");
      setCheckOut(response?.data?.isCheckOut)
    } catch (error) {
      console.error("Error fetching attendance overview:", error);
    }
  };

  const updateChartData = (apiData) => {
    let formattedData = [];

    if (apiData && typeof apiData === "object") {
      formattedData = Object.keys(apiData).map((date) => {
        const [hours, minutes, seconds] = apiData[date].split(":").map(Number);
        const totalHours = hours + minutes / 60 + seconds / 3600;
        return { x: date, y: totalHours };
      });

      setChartOptions((prevOptions) => ({
        ...prevOptions,
        xaxis: {
          ...prevOptions.xaxis,
          categories: formattedData.map((item) => item.x),
        },
        colors: formattedData.map((item) => {
          if (item.y >= 9) return "#3FC28A";
          if (item.y >= 6) return "#FEB85B";
          return "#F45B69";
        }),
      }));

      setSeries([{ name: "Average Time", data: formattedData }]);
    }
  };

  useEffect(() => {
    if (empId) {
      fetchDashboardData(empId);
    }
  }, [empId]);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(new Date());
    }, 10);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (isClockedIn) {
      // Update the timer every second
      const timerInterval = setInterval(() => {
        setTimer((prevTimer) => prevTimer + 1);
      }, 1000);

      return () => clearInterval(timerInterval);
    }
  }, [isClockedIn]);

  useEffect(() => {
    if (clockInTime && clockOutTime) {
      const start = new Date(clockInTime);
      const end = new Date(clockOutTime);
      const diff = Math.abs(end - start);
      const hours = Math.floor(diff / (1000 * 60 * 60));
      const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
      setDuration(`${hours} hrs ${minutes} mins`);
    }
  }, [clockOutTime]);

  const handleClockIn = () => {
    // setClockInTime(new Date());
    // setClockOutTime(null);
    // setDuration(null);
    // setIsClockedIn(true);
    // setTimer(0);
    setIsOpen(true);
  };

  const handleClockOut = () => {
    // setClockOutTime(new Date());
    // setIsClockedIn(false);
    // setTimer(0);
    setClockOutModalIsOpen(true);
  };

  const formatDate = (dateString) => {
    if (!dateString) return "-";

    const dateObj = new Date(dateString);
    if (isNaN(dateObj.getTime())) return "-";

    const hours = String(dateObj.getHours()).padStart(2, "0");
    const minutes = String(dateObj.getMinutes()).padStart(2, "0");
    const seconds = String(dateObj.getSeconds()).padStart(2, "0");

    return `${hours}:${minutes}:${seconds}`;
  };


  const formatStringMonth = (dateStr) => {
    if (!dateStr) return '';

    const date = new Date(dateStr);
    if (isNaN(date.getTime())) {
      return '';
    }

    const options = { year: 'numeric', month: 'short', day: '2-digit' };
    const formattedDate = date.toLocaleDateString('en-US', options);

    // Adjust the format to "Sep-10-2024"
    const [month, day, year] = formattedDate.split(' ');
    return `${month}-${day.replace(',', '')}-${year}`;
  };



  const getColors = () => {
    // Check if series and series[0] are defined
    if (series && series[0] && series[0].data) {
      return series[0].data.map((item) => {
        if (item.y >= 9) return "#3FC28A";
        if (item.y >= 6) return "#FEB85B";
        return "#F45B69";
      });
    }
    return [];
  };

  const defaultChartOptions = {
    chart: {
      type: "bar",
      height: 300,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "40%",
        borderRadius: 1,
        distributed: true,
        dataLabels: {
          enabled: false,
        },
        formatter: (value) => value,
        dataLabels: {
          position: 'top',
          formatter: (value) => value,

          style: {
            fontSize: '8px',
            fontWeight: 'bold',
          },
        }

      },

      dataLabels: {
        enabled: false,
        style: {
          colors: ['#333']
        },
        offsetY: 25
      },
    },
    xaxis: {
      categories: [],
      title: {
        text: "Date",
        offsetY: -20,
      },
      labels: {
        // formatter: (value) => value,
        formatter: (value) => {
          if (typeof value === 'string') {
            const [month, day] = value.split("-");
            if (month && day) {
              return `${month}-${day}`;
            }
          }
          return value;
        },
      },
    },
    yaxis: {
      title: {
        text: "Average Time",
      },
      labels: {
        formatter: (value) => value,
      },
      tickAmount: 12,
    },
    colors: getColors(),
    tooltip: {
      y: {
        formatter: (value) => value.toFixed(2),
      },
    },
    dataLabels: {
      enabled: false,
    },
  };

  const [chartOptions, setChartOptions] = useState(defaultChartOptions);

  const getStatusButton = (leaveStatus) => {
    if (leaveStatus === "APPROVED") {
      return <div className="leave-list-active">Approved</div>;
    } else if (leaveStatus === "REJECTED") {
      return <div className="leave-list-inactive">Rejected</div>;
    } else if (leaveStatus === "PENDING") {
      return <div className="leave-list-pending">Pending</div>;
    } else if (leaveStatus === "CANCELLED") {
      return <div className="leave-list-cancel">Cancelled</div>;
    } else if (leaveStatus === "APPROVEDANDCANCELLED") {
      return <div className="leave-list-cancel">Approved&Cancelled</div>;
    }
    return null;
  };

  const formatDuration = (durationString) => {
    if (!durationString) return "-- : --";

    const [hoursStr, minutesStr, secondsStr] = durationString.split(":");

    const hours = parseInt(hoursStr, 10);
    const minutes = parseInt(minutesStr, 10);

    return `${String(hours).padStart(2, "0")}h ${String(minutes).padStart(
      2,
      "0"
    )}m`;
  };

  const lastFiveAttendance = dashboardData?.attendanceReports
    ? dashboardData.attendanceReports
      .sort((a, b) => new Date(b.date) - new Date(a.date))
      .slice(0, 5)
    : [];

  const lastFiveLeaves = dashboardData?.leaveReports
    ? dashboardData.leaveReports
      .sort((a, b) => new Date(b.appliedDate) - new Date(a.appliedDate))
      .slice(0, 5)
    : [];

  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const validationSchema = Yup.object().shape({
    startDate: Yup.date().required("Date is required"),
    duration: Yup.number().required("Duration is required"),

    isHalfDay: Yup.boolean().required("HalfDay is required"),
    comments: Yup.string()
      .required("Comments is required")
      .trim()
      .notOneOf([""], "Field cannot be empty"),
  });
  // let attendanceId = sessionService?.getAttendanceId();

  const formik = useFormik({
    initialValues: InitialValues,
    validationSchema: validationSchema,
    onSubmit: (values) => { },
  });

  const Types = [
    { value: "SICK_LEAVE", name: "Sick Leave" },
    { value: "PERSONAL_LEAVE", name: "Personal Leave" },
    { value: "PRIVILEGE_LEAVE", name: "Privilege Leave" },
    { value: "WFH", name: "Wfh" },
    { value: "TWO_HOUR_PERMISSION", name: "Two Hours Permission" },
    { value: "LOSS_OF_PAY", name: "Loss Of Pay" },
  ];

  const days = [
    { value: "STARTDAY", name: "Start Day" },
    { value: "ENDDAY", name: "End Day" },
    { value: "STARTANDENDDAY", name: "Start and End Day" },
    { value: "ALLDAYS", name: "All Day" },
  ];

  useEffect(() => {
    if (!dates || dates.length === 0) {
      setInputFocused(false);
    }
  }, [dates]);

  useEffect(() => {
    if (dates && dates.length) {
      setInputFocused(true);
    }
  }, [dates]);

  const [holidays, setHolidays] = useState(null);
  useEffect(() => {
    axiosService
      .get(LEAVE_URL.GET_ALL_HOLIDAYS)
      .then((res) => {
        setHolidays(res.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  function calculateDuration() {
    const start = new Date(formik.values.startDate);
    const end = new Date(formik.values.endDate);
    let days = 0;

    while (start <= end) {
      const dayOfWeek = start.getDay();
      const date = start.getDate();
      const month = start.getMonth() + 1;

      const isWeekend = dayOfWeek === 0 || dayOfWeek === 6;
      const isHoliday = holidays.some((holiday) => {
        const holidayDate = new Date(holiday.holidayDate);
        return (
          holidayDate.getDate() === date && holidayDate.getMonth() + 1 === month
        );
      });

      if (!isWeekend && !isHoliday) {
        days++;
      }
      start.setDate(start.getDate() + 1);
    }

    if (
      formik.values.halfType &&
      new Date(formik.values.startDate) > new Date(formik.values.endDate)
    ) {
      return 0;
    }

    if (formik.values.halfType) {
      if (
        formik.values.halfType === "STARTDAY" ||
        formik.values.halfType === "ENDDAY"
      ) {
        return days - 0.5;
      } else if (formik.values.halfType === "STARTANDENDDAY") {
        return days - 1;
      } else if (formik.values.halfType === "ALLDAYS") {
        return days / 2;
      }
    }
    return days;
  }

  const getDuration = () => {
    if (formik.values.startDate && formik.values.endDate) {
      return calculateDuration();
    }
    return "";
  };

  function editObject(id) {
    const filteredObject = attendanceData.filter((a) => a.leaveId === id);

    setLeaveId(filteredObject[0].leaveId);

    formik.values.startDate = filteredObject[0].startDate;
    formik.values.endDate = filteredObject[0].endDate;
    formik.values.duration = filteredObject[0].duration;

    setDates([
      new Date(filteredObject[0].startDate),
      new Date(filteredObject[0].endDate),
    ]);

    setStartDateWithDifference(filteredObject[0].duration);

    formik.values.leaveType = filteredObject[0].leaveType;
    setShowStartDayInput(filteredObject[0].isHalfDay);

    formik.values.halfType = filteredObject[0].halfType;
    formik.values.comments = filteredObject[0].comments;
    setBalanceType(leaveBalances[filteredObject[0].leaveType]);
    setAppComments(filteredObject[0].requestComments);
    handleClickOpen();
  }

  const handleEditSubmit = async () => {
    const editData = {
      leaveId: leaveId,
      employee: {
        empId,
      },

      startDate: formik.values.startDate,
      endDate: formik.values.endDate,
      duration: duration,
      leaveType: formik.values.leaveType,
      halfType: formik.values.halfType,
      isHalfDay: showStartDayInput,
      comments: formik.values.comments,
    };

    try {
      const response = await axiosService.put(LEAVE_URL.EDIT_LEAVES, editData);
      if (response.status === 200) {
        toast.success("Leave Request Updated Successfully!", {
          autoClose: 3000,
          closeButton: false,
          style: { backgroundColor: "green", color: "white" },
        });
        handleClose();
        handleResetForm();
        setLeaveId();
        // fetchData();
      }
    } catch (error) {
      console.error("Error:", error);
      if (error?.response?.data?.message.startsWith("E")) {
        toast.error(error?.response?.data?.message.split(":")[1]);
      } else {
        toast.error(error?.response?.data?.message);
      }
    }
  };

  const handleAddSubmit = async () => {
    const formData = {
      employee: {
        empId,
      },
      startDate: formik.values.startDate,
      endDate: formik.values.endDate,
      duration: duration,
      leaveType: formik.values.leaveType,
      isHalfDay: showStartDayInput ? showStartDayInput : false,
      comments: formik.values.comments,
    };
    if (showStartDayInput) {
      formData.halfType = formik.values.halfType;
    }

    if (!formik.values.leaveType) {
      toast.error("Leave type is manditory.");
      return;
    }

    try {
      const response = await axiosService.post(
        LEAVE_URL.ADD_LEAVES,
        formData,
        {}
      );
      if (response.status === 200) {
        toast.success("Leave Request Submitted Successfully!", {
          autoClose: 3000,
          closeButton: false,
          style: { backgroundColor: "green", color: "white" },
        });
        formik.resetForm();
        handleResetForm();
        handleClose();
        // fetchData();

        LeaveMaster();
      }
    } catch (error) {
      console.error("Error:", error?.response?.data?.message);

      if (error?.response?.data?.message.startsWith("E")) {
        toast.error(error?.response?.data?.message.split(":")[1]);
      } else {
        toast.error(error?.response?.data?.message);
      }
    }
  };

  const LeaveMaster = () => {
    axiosService
      .get(`${LEAVE_URL.GET_EMPLOYEE_LEAVES}${empId}`)
      .then((res) => {
        setLeaveBalances({
          SICK_LEAVE: res.data.sickLeaveBalance,
          PERSONAL_LEAVE: res.data.personalLeaveBalance,
          TWO_HOUR_PERMISSION: res.data.permissionBalance,
          WFH: res.data.wfhBalance,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    LeaveMaster();
  }, []);

  const handleResetForm = () => {
    setDates(null);
    setStartDateWithDifference(0);
    setBalanceType("");
    formik.values.leaveType = "";
    setShowStartDayInput("");
    formik.values.halfType = "";
    formik.values.comments = "";
    formik.resetForm();
  };

  const handleClose = async () => {
    formik.values.leaveType = "";
    formik.values.comments = "";
    formik.values.halfType = "";
    setDates(null);

    setBalanceType(null);
    setStartDateWithDifference(0);
    setShowStartDayInput("");

    setLeaveId();

    setInitialValues({
      dates: [],
      duration: "",
      isHalfDay: false,
      comments: "",
    });
    await setViewDialog(false);
    await setOpen(false);
  };

  function convertDate(a) {
    const date = new Date(a);

    const year = date?.getFullYear();
    const month = String(date?.getMonth() + 1).padStart(2, "0");
    const day = String(date?.getDate()).padStart(2, "0");

    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  }

  function handleDate(e) {
    setDates(e.value);

    if (
      e.value &&
      e.value != null &&
      e.value[0] != null &&
      e.value[1] != null
    ) {
      const Date1 = moment(e.value[0]);
      const Date2 = moment(e.value[1]);
      const differenceInDays = Date2.diff(Date1, "days") + 1;

      if (showStartDayInput) {
        setStartDateWithDifference(differenceInDays - 0.5);
      } else {
        setStartDateWithDifference(differenceInDays);
      }
    }

    if (e.value[1]) {
      formik.values.startDate = convertDate(e.value[0]);
      formik.values.endDate = convertDate(e.value[1]);
    }
  }

  const handleChangeLeaveType = (e) => {
    setBalanceType(leaveBalances[e.value]);
    formik.values.leaveType = e.value;
  };

  const toggleStartDayInput = (event) => {
    const isChecked = event.target.checked;
    if (isChecked) {
      setStartDateWithDifference(startDateWithDifference - 0.5);
    } else {
      formik.setFieldValue("halfType", "");
    }

    setShowStartDayInput(isChecked);
  };

  // Webcam

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };
  const closeClockOutModal = () => {
    setClockOutModalIsOpen(false);
    // setAttendanceId(null);
  };

  const SaveMarkIn = async () => {
    try {
      const imageSrc = webcamRef.current.getScreenshot();

      if (!imageSrc) {
        console.error("Failed to capture image");
        return;
      }
      setImgSrcIn(imageSrc);
      closeModal();
      const blobImage = await fetch(imageSrc).then((res) => res.blob());
      const imageFile = new File([blobImage], { type: "image/jpeg" });
      const response = await axiosService.postFile(
        `${ATTENDANCE_URL.ADD_ATTENDANCE_IN}${empId}`,
        imageFile,
        "markInImage"
      );
      if (response?.status === 200) {
        console.log(response, "response 11111111111");
        setImgSrcIn(null);
        fetchDashboardData(empId);
        toast?.success(response?.data?.message);
        toast.success("Clock In successful!");
      } else {
        toast.error(response?.data?.message);
      }
    } catch (error) {
      if (error?.response?.data?.message.startsWith("E")) {
        toast.error(error?.response?.data?.message.split(":")[1]);
      } else {
        toast.error(error?.response?.data?.message);
      }
    } finally {
      setImgSrcIn(null);
    }
  };

  const SaveMarkOut = async () => {
    try {
      const imageSrc = webcamRef.current.getScreenshot();

      if (!imageSrc) {
        console.error("Failed to capture image");
        return;
      }
      setImgSrcOut(imageSrc);
      const blobImage = await fetch(imageSrc).then((res) => res.blob());
      const imageFile = new File([blobImage], { type: "image/jpeg" });
      const response = await axiosService.postFile(
        `${ATTENDANCE_URL.ADD_ATTENDANCE_OUT}${empId}?attendanceId=${attendanceId}`,
        imageFile,
        "markOutImage"
      );
      if (response?.status === 200) {
        setImgSrcIn(null);
        setAttendanceId(null);
        fetchDashboardData(empId);
        toast?.success(response?.data?.message);
        toast.success("Clock Out successful!");
      } else {
        toast.error(response?.data?.message);
      }
    } catch (error) {
      if (error?.response?.data?.message.startsWith("E")) {
        toast.error(error?.response?.data?.message.split(":")[1]);
      } else {
        toast.error(error?.response?.data?.message);
      }
    } finally {
      setImgSrcOut(null);
      closeClockOutModal();
    }
  };


  return (
    <div className="mydashboard-main-div"
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "12px",
        width: "100%",
        fontFamily: "Lexend",
      }}
    >
      <div
        className="mydashboard-top-section"
        style={{
          display: "flex",
          justifyContent: "space-between",
          gap: "12px",
          width:"100%"
        }}
      >
        <div style={{ display: "flex", gap: "12px", width: "50%" }}>
          <div
            className="mydashboard-top-left-card"
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              alignItems: "center",
              padding: "15px",
              backgroundColor: "#ffffff",
              borderRadius: "20px",
              width: "-webkit-fill-available",
              border: "1px solid #EFF0F6",
              justifyContent: "center",
            }}
          >
            <div
              className="mydashboard-top-clock-title"
              style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "8px" }}
            >
              <img src={Clock} alt="clock_image" style={{ marginBottom: "2px" }} />
              <h6>Current Month Avg.</h6>
            </div>
            <span>{formatDuration(dashboardData?.averageMonthlyDuration)}</span>
          </div>
          <div
            className="mydashboard-top-middle-card"
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              alignItems: "center",
              padding: "15px",
              backgroundColor: "#ffffff",
              borderRadius: "20px",
              width: "-webkit-fill-available",
              border: "1px solid #EFF0F6",
              justifyContent: "center",
            }}
          >
            <div
              className="mydashboard-top-middle-clock-title"
              style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "8px" }}
            >
              <img src={Clock} alt="clock_image" style={{ marginBottom: "2px" }} />
              <h6>Current Week Avg.</h6>
            </div>
            <span>{formatDuration(dashboardData?.averageMonthlyDuration)}</span>
          </div>
        </div>
        <div
          className="mydashboard-top-right-card"
          style={{
            display: "flex",
            gap: "10px",
            alignItems: "center",
            backgroundColor: "#ffffff",
            borderRadius: "20px",
            width: "-webkit-fill-available",
            border: "1px solid #EFF0F6",
            width: "calc(50% - 12PX)",
            justifyContent: "space-around",
          }}
        >
          <div
            className="mydashboard-to-right-attendance"
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "3px",
              alignItems: "center",
              padding: "15px",
              width: "250px"
            }}
          >
            <h6>Your Attendance</h6>
            <span className="mydashboard-to-right-attendance-startTime">
              {formatDate(currentTime)}
            </span>
            {attendanceId && checkOut ? (
              <button
                onClick={() => {
                  handleClockOut();
                }}
                style={{ backgroundColor: "#E25319" }}
              >
                Clock Out
              </button>
            ) : (
              <button
                onClick={handleClockIn}
                style={{ backgroundColor: "#3fc28a" }}
              >
                Clock In
              </button>
            )}
          </div>
          <div
            className="mydashboard-to-right-time"
            style={{
              borderLeft: "1px solid #0000001A",
              padding: "20px",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              gap: "5px",
              width: "250px"
            }}
          >
            <div>
              <span>Date:</span>
              <span>{formatStringMonth(dashboardData?.firstCheckIn)} </span>
            </div>
            <div>
              <span>Check In:</span>
              <span>{formatDate(dashboardData?.firstCheckIn)}</span>
            </div>
            <div>
              <span>Check Out:</span>
              <span>{formatDate(dashboardData?.lastCheckout)}</span>
            </div>
            <div>
              <span>Duration:</span>
              {dashboardData?.duration ? (
                <span>{dashboardData?.duration}</span>) : ("-")}
            </div>
          </div>
        </div>
      </div>

      <div className="mydashboard-middle-section">
        <div
          className="mydashboard-middle-left"
          style={{
            display: "flex",
            flexDirection: "column",
            padding: "20px 20px 0 20px",
            backgroundColor: "#ffffff",
            borderRadius: "20px",
            width: "calc(100% - 20px)",
            border: "1px solid #EFF0F6",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {" "}
            <h5 style={{ fontSize: "16px", fontWeight: "600", margin: "0" }}>Average Time in Office (Last 15 days)</h5>
          </div>
          <Chart
            options={chartOptions || defaultChartOptions}
            series={series}
            type="bar"
            height={300}
          // width={550}
          />
        </div>
        <div className="mydashboard-middle-right">
          <div
            className="mydashboard-attendance-table"
            style={{ backgroundColor: "#FFFFFF" }}
          >
            <div className="myreportees-table-attendance-overview-header">
              <h5 style={{ fontSize: "16px", fontWeight: "600", margin: '0' }}>Your Attendance</h5>
              <button onClick={() => navigate("/attendance")}>View All</button>
            </div>
            <div className="myreportees-table-attendance-overview">
              <table
                className="reportees-views-list-table"
                style={{ width: "100%" }}
              >
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Check In</th>
                    <th>Check Out</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {lastFiveAttendance?.map((data, index) => (
                    <tr key={index} style={{ height: "47px" }}>
                      {/* <td className="myreportees-name-ans-image">
                        {" "}
                        {data?.employee?.profileImage ? (
                          <img src={data?.employee?.profileImage} alt="employee_image" />
                        ) : (
                          <img src={sampleImage} alt="employee_image" />
                        )}
                        {data?.name}
                      </td> */}
                      <td>{formatStringMonth(data?.date)}</td>
                      <td>
                        {data?.checkInTime ? (
                          <span>
                            {new Date(data?.checkInTime).toLocaleTimeString(
                              [],
                              {
                                hour: "2-digit",
                                minute: "2-digit",
                                hour12: true,
                              }
                            )}
                          </span>
                        ) : (
                          <span>-</span>
                        )}
                      </td>
                      <td>
                        {data?.checkOutTime ? (
                          <span>
                            {new Date(data?.checkOutTime).toLocaleTimeString(
                              [],
                              {
                                hour: "2-digit",
                                minute: "2-digit",
                                hour12: true,
                              }
                            )}
                          </span>
                        ) : (
                          <span>-</span>
                        )}
                      </td>
                      <td
                        className="myreportees-attendance-status-btn"
                        style={{
                          width: "22%",
                          color:
                            data?.attendanceStatus === "ONTIME"
                              ? "#3FC28A"
                              : data?.attendanceStatus === "LATE"
                                ? "#F45B69"
                                : "black",
                          backgroundColor:
                            data?.attendanceStatus === "ONTIME"
                              ? "#3FC28A33"
                              : data?.attendanceStatus === "LATE"
                                ? "#F45B6933"
                                : "white",
                        }}
                      >
                        {data?.attendanceStatus}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div className="mydashboard-bottom-section">
        <div
          className="myreportees-table-chart-div"
          style={{ backgroundColor: "#FFFFFF", width: "50%" }}
        >
          <div className="myreportees-leave-overview-section">
            <h5
              style={{ fontSize: "16px", fontWeight: "600", margin: "12px 0" }}
            >
              Your Leave Overview
            </h5>
            <button
              className="myreportees-leave-apply-btn"
              onClick={handleClickOpen}
            >
              <span style={{ marginRight: "8px" }}>
                <Vector style={{ fontSize: "20px" }} />
              </span>
              Apply Leave
            </button>
            <button onClick={() => navigate("/leaves")}>View all</button>
          </div>

          <div className="mydashboard-leave-overview">
            <table
              className="reportees-views-list-table"
              style={{ width: "100%" }}
            >
              <thead>
                <tr>
                  <th>Applied On</th>
                  <th>Date</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {lastFiveLeaves?.map((e, index) => (
                  <tr key={index} style={{ height: "47px" }}>
                    {/* <td className="myreportees-leave-overview">
                      {employeeImage ? (
                        <img src={employeeImage} alt="employee_image" />
                      ) : (
                        <span>No Image</span>
                      )}
                      {e?.employee?.employeeFirstName}{" "}
                      {e?.employee?.employeeLastName}
                    </td> */}
                    <td>{formatStringMonth(e?.appliedDate)}</td>

                    <td>
                      {formatStringMonth(e.startDate)} -{" "}
                      {formatStringMonth(e.endDate)}
                    </td>

                    <td>{getStatusButton(e.leaveStatus)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        <Dialog
          sx={{ "& .MuiDialog-paper": { width: "40%", borderRadius: "20px" } }}
          maxWidth="100px"
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          classes={{ paper: "custom-dialog-paper" }}
        >
          <form onSubmit={formik.handleSubmit}>
            <DialogActions
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "left",
                borderBottom: "1px solid lightgrey",
                padding: "10px 25px",
                // paddingRight: "10px",
                // marginLeft: "20px",
                // marginRight: "20px",
                // marginTop: "-16px",
              }}
            >
              <span>
                <p className="leave-title">
                  {viewDialog
                    ? "View Leave"
                    : leaveId
                      ? "Edit Leave"
                      : "Apply Leave"}
                </p>
              </span>
            </DialogActions>
            <DialogTitle id="alert-dialog-title">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "20px",
                  marginTop: "10px",
                }}
              >
                <div
                  className="leave-select-date-range"
                  style={{
                    position: "relative",
                    width: "48%",
                    paddingTop: "12px",
                  }}
                >
                  <span
                    className={`p-float-label ${dates ? "p-input-filled" : ""}`}
                    style={{ width: "100%" }}
                  >
                    <Calendar
                      id="startDate"
                      name="startDate"
                      value={dates}
                      dateFormat="yy-mm-dd"
                      onChange={(e) => {
                        handleDate(e);
                      }}
                      selectionMode="range"
                      showIcon
                      readOnlyInput
                      disabled={viewDialog}
                      hideOnRangeSelection
                      style={{
                        width: "100%",
                      }}
                    />

                    <label
                      htmlFor="startDate"
                      style={{
                        position: "absolute",
                        top: inputFocused ? "0" : "0",
                        left: "0",
                        transform: inputFocused
                          ? "translate(7px, -23px)"
                          : "translate(10px, 17px)",
                        transition: "0.2s all ease",
                        pointerEvents: "none",
                        color: viewDialog ? " rgba(0, 0, 0, 1)" : "#000",
                        backgroundColor: "rgba(255, 255, 255, 0.8)",
                        padding: "0 5px",
                        fontSize: inputFocused ? "16px" : "16px",
                        fontWeight: "300",
                      }}
                    >
                      Date Range
                    </label>
                  </span>
                </div>

                <div style={{ width: "48%" }}>
                  <span
                    className="p-float-label"
                    style={{ paddingTop: "12px" }}
                  >
                    <InputText
                      className="leave-duration"
                      onChange={formik.handleChange}
                      // onBlur={formik.handleBlur}
                      name="Duration"
                      style={{
                        width: "100%",
                        height: "48px",
                        borderRadius: "10px",
                        border:
                          editObject?.duration || startDateWithDifference
                            ? "1px solid rgba(107 105 105)"
                            : "1px solid rgba(162, 161, 168, 0.2)",
                        backgroundColor:
                          editObject?.duration || startDateWithDifference
                            ? "white"
                            : "rgba(0, 0, 0, 0.05)",
                        color: "rgba(0, 0, 0, 1)",
                      }}
                      // value={
                      //   editObject?.duration || startDateWithDifference || null
                      // }
                      value={getDuration()}
                      readOnly
                      disabled={viewDialog}
                    ></InputText>
                    <label className="leave-floating-label" >Duration</label>
                  </span>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "20px",
                  width: "100%",
                }}
              >
                <div style={{ width: "48%" }}>
                  <span className="p-float-label ">
                    <Dropdown
                      className="leave-absence-type"
                      name="leaveType"
                      onBlur={formik.handleBlur}
                      value={formik.values.leaveType}
                      optionLabel="name"
                      options={Types}
                      onChange={handleChangeLeaveType}
                      style={{
                        width: "100%",
                        height: "48px",
                        borderRadius: "10px",
                        // border: "1px solid rgba(162, 161, 168, 0.2)",
                        border: formik.values.leaveType
                          ? "1px solid rgba(107 105 105)"
                          : "1px solid rgba(162, 161, 168, 0.2)",
                        backgroundColor: formik.values.leaveType
                          ? "white"
                          : "rgba(0, 0, 0, 0.05)",
                        color: "rgba(0, 0, 0, 1)",
                        marginTop: "14px",
                        alignItems: "center",
                      }}
                      disabled={viewDialog}
                    >
                      <option
                        selected
                        disabled
                        hidden
                        value=""
                        className="leave-absence-type"
                      ></option>
                    </Dropdown>
                    <label className="leave-floating-label">Absence Type</label>
                  </span>
                </div>
                {!viewDialog && (
                  <div style={{ width: "48%" }}>
                    <span className="p-float-label">
                      <InputText
                        name="balanceType"
                        className="leave-balance-type"
                        onChange={(e) => setBalanceType(e.target.value)}
                        value={balanceType}
                        style={{
                          width: "100%",
                          height: "48px",
                          borderRadius: "10px",
                          border: balanceType
                            ? "1px solid rgba(107 105 105)"
                            : "1px solid rgba(162, 161, 168, 0.2)",
                          backgroundColor: balanceType
                            ? "white"
                            : "rgba(0, 0, 0, 0.05)",
                          color: "rgba(0, 0, 0, 1)",
                          marginTop: "14px",
                        }}
                        readOnly
                        disabled={
                          formik.values.leaveType === "LOSS_OF_PAY" ||
                          viewDialog
                        }
                      ></InputText>
                      {formik.touched.balanceType &&
                        formik.errors.balanceType ? (
                        <div className="writing-parameter-formik-error">
                          {formik.errors.balanceType}
                        </div>
                      ) : null}
                      <label className="leave-floating-label">
                        Balance Leave
                      </label>
                    </span>
                  </div>
                )}
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "20px",
                  width: "100%",
                }}
              >
                <div style={{ width: "48%", marginTop: "20px" }}>
                  <input
                    name="isHalfDay"
                    type="checkbox"
                    onChange={toggleStartDayInput}
                    // onBlur={formik.handleBlur}
                    value={formik.values.isHalfDay}
                    checked={showStartDayInput}
                    disabled={viewDialog}
                  />

                  <label className="half-day-label">Select Half Day</label>
                </div>
                <div style={{ width: "48%" }}>
                  {showStartDayInput && (
                    <span className="p-float-label ">
                      <Dropdown
                        className="leave-start-day"
                        name="halfType"
                        disabled={viewDialog}
                        onChange={(event) => {
                          formik.handleChange(event);
                        }}
                        // onBlur={formik.handleBlur}
                        value={formik.values.halfType}
                        options={days}
                        optionLabel="name"
                        style={{
                          width: "100%",
                          height: "48px",
                          borderRadius: "10px",
                          border: formik.values.halfType
                            ? "1px solid rgba(107 105 105)"
                            : "1px solid rgba(162, 161, 168, 0.2)",
                          backgroundColor: formik.values.halfType
                            ? "white"
                            : "rgba(0, 0, 0, 0.05)",
                          color: "rgba(0, 0, 0, 1)",
                          // marginTop: "10px",
                          alignItems: "center",
                        }}
                      >
                        <option
                          selected
                          disabled
                          hidden
                          value=""
                          className="leave-start-day"
                        ></option>
                      </Dropdown>
                      <label className="leave-floating-label" style={{ top: "0", bottom: "0", display: "flex", padding: "0" }}>Days</label>
                    </span>
                  )}
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  marginBottom: "20px",
                  width: "100%",
                }}
              >
                <div style={{ width: "100%" }}>
                  <span className="p-float-label">
                    <InputText
                      disabled={viewDialog}
                      className="leave-comments"
                      name="comments"
                      onChange={formik.handleChange}
                      // onBlur={formik.handleBlur}
                      value={formik.values.comments}
                      style={{
                        width: "100%",

                        borderRadius: "10px",
                        border: formik.values.comments
                          ? "1px solid rgba(107 105 105)"
                          : "1px solid rgba(162, 161, 168, 0.2)",
                        backgroundColor: formik.values.comments
                          ? "white"
                          : "rgba(0, 0, 0, 0.05)",
                        color: "rgba(0, 0, 0, 1)",
                        marginTop: "10px",
                        alignItems: "center",
                      }}
                    />

                    <label className="leave-floating-label">Comments</label>
                  </span>
                </div>
                {viewDialog && appComments && (
                  <div style={{ width: "100%", marginTop: "10px" }}>
                    <label className="leave-floating-label">
                      Approve/Reject Comments
                    </label>
                    <input
                      disabled={viewDialog}
                      className="leave-comments"
                      name="comments"
                      value={appComments}
                      style={{
                        width: "100%",
                        borderRadius: "10px",
                        border: "1px solid rgba(107, 105, 105)",
                        backgroundColor: "white",
                        color: "rgba(0, 0, 0, 1)",
                        marginTop: "30px",
                        alignItems: "center",
                      }}
                    />
                  </div>
                )}
              </div>
            </DialogTitle>

            <DialogContent>
              <DialogContentText
                id="alert-dialog-description"
                className="add-hol"
              >
                <div
                  className=""
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginBottom: "15px",
                  }}
                >
                  {viewDialog ? (
                    <div className="">
                      <button
                        type="button"
                        className="leave-cancel-bttn"
                        onClick={handleClose}
                      >
                        BACK
                      </button>
                    </div>
                  ) : (
                    <div className="">
                      <button
                        type="button"
                        className="leave-cancel-bttn"
                        onClick={handleClose}
                      >
                        CANCEL
                      </button>
                    </div>
                  )}
                  {leaveId ? (
                    <div className="">
                      <button
                        type="submit"
                        className="leave-save-bttn"
                        onClick={handleEditSubmit}
                        hidden={viewDialog}
                      >
                        UPDATE
                      </button>
                    </div>
                  ) : (
                    <div className="">
                      <button
                        type="submit"
                        className="leave-save-bttn"
                        onClick={handleAddSubmit}
                      >
                        SAVE
                      </button>
                    </div>
                  )}
                </div>
              </DialogContentText>
            </DialogContent>
          </form>
        </Dialog>

        <div className="myreportees-table-chart-div">
          <div>
            <Modal
              isOpen={modalIsOpen || clockOutModalIsOpen}
              style={customStyles}
              contentLabel="Webcam Modal"
            >
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  overflow: "hidden",
                }}
              >
                {imgSrcIn || imgSrcOut ? (
                  <img
                    src={imgSrcIn || imgSrcOut}
                    alt="webcam"
                    style={{
                      width: "auto",
                      height: "100%",
                      borderRadius: "20px",
                    }}
                  />
                ) : (
                  <Webcam
                    style={{
                      width: "auto",
                      height: "100%",
                      position: "relative",
                      borderRadius: "20px",
                    }}
                    ref={webcamRef}
                    screenshotFormat="image/png"
                  />
                )}

                <button
                  className={`attendence-list-clock-${modalIsOpen ? "in" : "out"
                    }-button`}
                  onClick={modalIsOpen ? SaveMarkIn : () => SaveMarkOut()}
                  style={{
                    position: "absolute",
                    bottom: "0",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                  }}
                >
                  {modalIsOpen ? "Clock In" : "Clock Out"}
                  <span className="mx-1">
                    {modalIsOpen ? <ClockIn /> : <ClockOut />}
                  </span>
                </button>
                {modalIsOpen && (
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={closeModal}
                  >
                    X
                  </button>
                )}
                {clockOutModalIsOpen && (
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={closeClockOutModal}
                  >
                    X
                  </button>
                )}
              </div>
            </Modal>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyDashboard;
