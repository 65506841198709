import { Pagination } from "flowbite-react";
import { Dropdown } from "primereact/dropdown";
import React, { useEffect, useRef, useState } from "react";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { useNavigate } from "react-router-dom";
import Dropdowns from "../../../../assets/images-svg/Drop_down";
import Edit from "../../../../assets/images-svg/Edit";
import LeftArrow from "../../../../assets/images-svg/LeftArrow";
import Plus from "../../../../assets/images-svg/Plus";
import RightArrow from "../../../../assets/images-svg/RightArrow";
import View from "../../../../assets/images-svg/View";
import { axiosService } from "../../../../services/axios.service";
import { ALL_EMPLOYEES_URL } from "../../constants/all-employees-urls";
import "./AllEmployeesList.css";

function AllEmployeesList() {
  const [attendanceData, setAttendanceData] = useState([]);
  const navigate = useNavigate();
  const cardsPerPageOptions = [10, 20, 30];
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(cardsPerPageOptions[0]);
  const [totalElements, setTotalElements] = useState(null);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [filteredData, setFilteredData] = useState([]);
  const [previousPage, setPreviousPage] = useState(1);

  const fetchData = async ({ employeeName, pageNumber, itemsPerPage }) => {
    try {
      const url = ALL_EMPLOYEES_URL.GET_ALL_EMPLOYEES;
      const params = {
        pageNumber,
        itemsPerPage,
        employeeName,
      };

      const response = await axiosService.get(url, params);
      const data = response?.data?.data?.data;

      setAttendanceData(data);
      setTotalElements(parseInt(response.data.data.totalElements));
    } catch (error) {
      console.error("Error fetching attendance data:", error);
    }
  };

  const getFilterUsers = async () => {
    try {
      const url = ALL_EMPLOYEES_URL.GET_ALL_EMPLOYEES_FILTER;
      const response = await axiosService.get(url);
      const data = response?.data?.data;
      if (data) {
        const employeeFirstNames = data.map((item) => {
          return { name: item.employeeFirstName + ' ' + item.employeeLastName, empId: item?.employeeId };
        });
        setFilteredData(employeeFirstNames);
      }
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  useEffect(() => {
    getFilterUsers();
  }, []);

  useEffect(() => {
    fetchData({
      employeeName: selectedEmployee,
      pageNumber: currentPage,
      itemsPerPage: recordsPerPage,
    });
  }, [selectedEmployee, currentPage, recordsPerPage]);

  const handleEmployeeSelect = (selectedOption) => {
    setSelectedEmployee(selectedOption);
    setPreviousPage(currentPage);

    fetchData({
      employeeName: selectedOption,
      pageNumber: currentPage,
      itemsPerPage: recordsPerPage,
    });
  };

  const options = filteredData.map((a) => ({
    label: a?.name,
    value: a?.empId,
  }));

  const containerRef = useRef();
  const onPageChange = (page) => {
    setCurrentPage(page);
    containerRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const handleRecordsPerPageChange = (e) => {
    setRecordsPerPage(parseInt(e.target.value));
    setCurrentPage(1);
  };

  const handleEdit = (rowData) => {
    navigate(
      `/all-employees/edit-employees?empName=${rowData?.employeeFirstName}`,
      {
        state: {
          viewData: rowData,
          type: "Edit",
        },
      }
    );
  };

  const handleView = (rowDataView) => {
    navigate(
      `/all-employees/view-employees?empName=${rowDataView?.employeeFirstName}`,
      {
        state: {
          viewData: rowDataView,
          type: "View",
        },
      }
    );
  };

  useEffect(() => {
    if (
      totalElements &&
      Math.ceil(totalElements / recordsPerPage) < previousPage
    ) {
      setCurrentPage(Math.ceil(totalElements / recordsPerPage));
    }
  }, [totalElements, recordsPerPage, previousPage]);

  return (
    <div className="w-100 employee-list-container" ref={containerRef}>
      <div className="employee-list-banner-main">
        <div className="employee-list-table-holder-main">
          <div className="container-fluid">
            <div className="row d-flex justify-content-between">
              <div className="employee-list-filter-input-container">
                <Dropdown
                  value={selectedEmployee}
                  onChange={(e) => handleEmployeeSelect(e.target.value)}
                  options={options}
                  filter={true}
                  showClear
                  placeholder="Employee"
                  className="custom-dropdown w-full md:w-14rem"
                  style={{ zIndex: "0" }}
                />

                {/* <div className="employee-list-filter-icon">
                  <Filter />
                </div> */}
              </div>
              <div className="employee-list-add-employee-button">
                <button
                  className="employee-add-new"
                  onClick={() => navigate("/all-employees/add-employees", {
                    state: {
                      type: "Add",
                    },
                  })}
                >
                  <span style={{ marginRight: "10px" }}>
                    <Plus />
                  </span>
                  Add New Employee
                </button>
              </div>
            </div>
          </div>
          <div className="employee-list-table-container">
            <table className="employee-list-table">
              <thead>
                <tr>
                  <th className="employee-list-table-lg-columns" scope="col">
                    Employee Name
                  </th>
                  <th className="employee-list-table-lg-columns" scope="col">
                    Employee ID
                  </th>
                  <th className="employee-list-table-lg-columns" scope="col">
                    Office Email
                  </th>
                  <th className="employee-list-table-lg-columns" scope="col">
                    Phone Number
                  </th>
                  <th className="employee-list-table-lg-columns" scope="col">
                    Status
                  </th>
                  <th className="employee-list-table-lg-columns" scope="col">
                    Action
                  </th>
                  <th className="employee-list-table-md-columns" scope="col">
                    Employee Name
                  </th>
                  <th className="employee-list-table-md-columns" scope="col">
                    Employee ID
                  </th>
                </tr>
              </thead>
              <tbody>
                {attendanceData.map((row, index) => (
                  <tr key={index}>
                    <td className="employee-list-table-lg-columns">
                      <div className="d-inline-flex gap-1 employee-list-table-profile mt-1">
                        <p className="m-1">
                          {row.employeeFirstName} {row.employeeLastName}
                        </p>
                      </div>
                    </td>
                    <td className="employee-list-table-lg-columns">
                      {row.employeeId}
                    </td>
                    <td className="employee-list-table-lg-columns">
                      {row.officeEmail}
                    </td>
                    <td className="employee-list-table-lg-columns">
                      {row.mobileNumber}
                    </td>
                    <td className="employee-list-table-lg-columns">
                      <div
                        className="employee-list-table-status"
                        style={{
                          background:
                            row.employeeStatus === "INACTIVE"
                              ? "#E2531933"
                              : "#3FC28A33",
                          color:
                            row.employeeStatus === "INACTIVE"
                              ? "#E25319"
                              : "#3FC28A",
                          fontSize: "14.5px"
                        }}
                      >
                        {row.employeeStatus}
                      </div>
                    </td>
                    <td className="employee-list-table-lg-columns">
                      <div className="action">
                        <span
                          onClick={() => handleView(row)}
                          style={{ marginRight: "10px", cursor: "pointer" }}
                        >
                          <View />
                        </span>
                        <span
                          onClick={() => handleEdit(row)}
                          style={{ cursor: "pointer" }}
                        >
                          <Edit />
                        </span>
                      </div>
                    </td>
                    <td className="employee-list-table-md-columns">
                      {row.employeeFirstName}
                    </td>
                    <td className="employee-list-table-md-columns">
                      {row.employeeId}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="row mt-2" style={{ boxShadow: "0px -2px  #F3EEEA" }}>
            <div className="col w-50 d-flex align-items-center employee-list-record-list">
              <span style={{ marginRight: "20px" }}>Showing</span>
              <div style={{ width: "fit-content", position: "relative" }}>
                <select
                  value={recordsPerPage}
                  onChange={handleRecordsPerPageChange}
                >
                  {cardsPerPageOptions.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
                <div className="employee-list-record-list-dropdown-icon">
                  <Dropdowns />
                </div>
              </div>
            </div>
            <div className="col w-50  pagination-container">
              {Math.ceil(totalElements / recordsPerPage) > 1 ? (
                <>
                  {totalElements && (
                    <Pagination
                      className="pagination d-inline-flex"
                      currentPage={currentPage}
                      totalPages={Math.ceil(totalElements / recordsPerPage)}
                      onPageChange={onPageChange}
                      nextLabel={<RightArrow />}
                      previousLabel={<LeftArrow />}
                      activePageLinkStyle={{ color: "#ffffff !important" }}
                      icon={<span>{currentPage}</span>}
                    />
                  )}
                </>
              ) : (
                <nav
                  class="pagination d-inline-flex"
                  activepagelinkstyle="[object Object]"
                  icon="[object Object]"
                >
                  <ul class="xs:mt-0 mt-2 inline-flex items-center -space-x-px">
                    <li>
                      <button
                        type="button"
                        class="cursor-not-allowed opacity-50 ml-0 rounded-l-lg border border-gray-300 bg-white px-3 py-2 leading-tight text-gray-500 enabled:hover:bg-gray-100 enabled:hover:text-gray-700 dark:border-gray-700 dark:bg-gray-800 dark:text-gray-400 enabled:dark:hover:bg-gray-700 enabled:dark:hover:text-white"
                      >
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M14.4685 17.5856C14.7919 17.3269 14.8444 16.8549 14.5856 16.5315L10.9604 12L14.5856 7.46849C14.8444 7.14505 14.7919 6.67308 14.4685 6.41432C14.145 6.15556 13.673 6.208 13.4143 6.53145L9.41429 11.5315C9.19516 11.8054 9.19516 12.1946 9.41429 12.4685L13.4143 17.4685C13.673 17.7919 14.145 17.8444 14.4685 17.5856Z"
                            fill="black"
                          ></path>
                        </svg>
                      </button>
                    </li>
                    <li aria-current="page">
                      <button
                        type="button"
                        class="w-12 border border-gray-300 py-2 leading-tight enabled:hover:bg-gray-100 enabled:hover:text-gray-700 enabled:dark:hover:bg-gray-700 enabled:dark:hover:text-white bg-cyan-50 text-cyan-600 hover:bg-cyan-100 hover:text-cyan-700 dark:border-gray-700 dark:bg-gray-700 dark:text-white"
                      >
                        1
                      </button>
                    </li>
                    <li>
                      <button
                        type="button"
                        class="cursor-not-allowed opacity-50 ml-0 rounded-l-lg border border-gray-300 bg-white px-3 py-2 leading-tight text-gray-500 enabled:hover:bg-gray-100 enabled:hover:text-gray-700 dark:border-gray-700 dark:bg-gray-800 dark:text-gray-400 enabled:dark:hover:bg-gray-700 enabled:dark:hover:text-white"
                      >
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M9.53157 17.5856C9.20812 17.3269 9.15568 16.8549 9.41444 16.5315L13.0396 12L9.41444 7.46849C9.15568 7.14505 9.20812 6.67308 9.53157 6.41432C9.85501 6.15556 10.327 6.208 10.5857 6.53145L14.5857 11.5315C14.8049 11.8054 14.8049 12.1946 14.5857 12.4685L10.5857 17.4685C10.327 17.7919 9.85501 17.8444 9.53157 17.5856Z"
                            fill="black"
                          ></path>
                        </svg>
                      </button>
                    </li>
                  </ul>
                </nav>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AllEmployeesList;
