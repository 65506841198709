import { Form, Formik } from "formik";
import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { axiosService } from "../../../../services/axios.service";
import { ALL_EMPLOYEES_URL } from "../../constants/all-employees-urls";
import CancelModal from "../cancel-modal/CancelModal";
import "./EmployeesDocuments.css";

import Dropzone from "react-dropzone";
import View from "../../../../assets/images-svg/View";

function EmployeesDocuments({ onSubmit, type, values, setActiveTab }) {
  const [modalOpen, setModalOpen] = useState(false);
  const navigate = useNavigate();
  const containerRef = useRef();
  //------ Start -----

  const [aadhaarImage, setAadhaarImage] = useState(null);
  const [aadhaarImageName, setAadhaarImageName] = useState(null);

  const [panImage, setPanImage] = useState(null);
  const [panImageName, setPanImageName] = useState(null);

  const [bankPassBookImage, setBankPassBookImage] = useState(null);
  const [bankPassBookImageName, setBankPassBookImageName] = useState(null);

  const [profileImage, setProfileImage] = useState(null);
  const [profileImageName, setProfileImageName] = useState(null);

  const onDrop1 = async (acceptedFiles) => {
    if (!acceptedFiles[0]) {
      toast.error("Please select a image file less than 2MB.");
    } else if (!acceptedFiles[0].type.startsWith("image/")) {
      toast.error(" Invalid file format,Please upload .png,.jpg,.jpeg files");
    } else {
      try {
        // if (acceptedFiles[0]?.name?.length > 150) {
        //   toast.error(UserConstants.IMAGE_LENGTH);

        //   return;
        // }

        setAadhaarImageName(acceptedFiles[0]?.name);

        // sessionstorageService.isLoading("true");

        const formData = new FormData();

        formData.append("file", acceptedFiles[0]);

        // const response = await axiosService.postFile(
        //   `${ADMISSION_MANAGEMENT_URL.FILE_UPLOAD_URL}`,

        //   acceptedFiles[0],

        //   true
        // );

        // if (response.status === 200) {
        //   setAadhaarImage(response.data.data);
        // }
      } catch (error) {
        toast.error(error.response.data.message);
      }
    }
  };

  const onDrop2 = async (acceptedFiles) => {
    if (!acceptedFiles[0]) {
      toast.error("Please select a image file less than 2MB.");
    } else if (!acceptedFiles[0].type.startsWith("image/")) {
      toast.error(" Invalid file format,Please upload .png,.jpg,.jpeg files");
    } else {
      try {
        // if (acceptedFiles[0]?.name?.length > 150) {
        //   toast.error(UserConstants.IMAGE_LENGTH);

        //   return;
        // }

        setPanImageName(acceptedFiles[0]?.name);

        // sessionstorageService.isLoading("true");

        const formData = new FormData();

        formData.append("file", acceptedFiles[0]);

        // const response = await axiosService.postFile(
        //   `${ADMISSION_MANAGEMENT_URL.FILE_UPLOAD_URL}`,

        //   acceptedFiles[0],

        //   true
        // );

        // if (response.status === 200) {
        //   setPanImage(response.data.data);
        // }
      } catch (error) {
        toast.error(error.response.data.message);
      }
    }
  };

  const onDrop3 = async (acceptedFiles) => {
    if (!acceptedFiles[0]) {
      toast.error("Please select a image file less than 2MB.");
    } else if (!acceptedFiles[0].type.startsWith("image/")) {
      toast.error(" Invalid file format,Please upload .png,.jpg,.jpeg files");
    } else {
      try {
        // if (acceptedFiles[0]?.name?.length > 150) {
        //   toast.error(UserConstants.IMAGE_LENGTH);

        //   return;
        // }

        setBankPassBookImageName(acceptedFiles[0]?.name);

        // sessionstorageService.isLoading("true");

        const formData = new FormData();

        formData.append("file", acceptedFiles[0]);

        // const response = await axiosService.postFile(
        //   `${ADMISSION_MANAGEMENT_URL.FILE_UPLOAD_URL}`,

        //   acceptedFiles[0],

        //   true
        // );

        // if (response.status === 200) {
        //   setBankPassBookImage(response.data.data);
        // }
      } catch (error) {
        toast.error(error.response.data.message);
      }
    }
  };

  const onDrop4 = async (acceptedFiles) => {
    if (!acceptedFiles[0]) {
      toast.error("Please select a image file less than 2MB.");
    } else if (!acceptedFiles[0].type.startsWith("image/")) {
      toast.error(" Invalid file format,Please upload .png,.jpg,.jpeg files");
    } else {
      try {
        // if (acceptedFiles[0]?.name?.length > 150) {
        //   toast.error(UserConstants.IMAGE_LENGTH);

        //   return;
        // }

        setProfileImageName(acceptedFiles[0]?.name);

        // sessionstorageService.isLoading("true");

        const formData = new FormData();

        formData.append("file", acceptedFiles[0]);

        // const response = await axiosService.postFile(
        //   `${ADMISSION_MANAGEMENT_URL.FILE_UPLOAD_URL}`,

        //   acceptedFiles[0],

        //   true
        // );

        // if (response.status === 200) {
        //   setProfileImage(response.data.data);
        // }
      } catch (error) {
        toast.error(error.response.data.message);
      }
    }
  };

  function convertDateFormat(dateString) {
    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
      return "";
    }
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getUTCHours()).padStart(2, "0");
    const minutes = String(date.getUTCMinutes()).padStart(2, "0");
    const seconds = String(date.getUTCSeconds()).padStart(2, "0");
    const isoDateString = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.000Z`;
    return isoDateString;
  }

  const newSave = async (values) => {
    let mergedValues;
    try {
      mergedValues = {
        ...values,

        supervisor: { empId: values?.supervisor?.empId },

        employeeDob: convertDateFormat(values?.employeeDob),
        joiningDate: convertDateFormat(values?.joiningDate),
        lastWorkingDate: convertDateFormat(values?.lastWorkingDate),
      };

      const response = await axiosService.post(
        `${ALL_EMPLOYEES_URL.ADD_EMPLOYEE_DATA}`,
        mergedValues
      );

      if (response.status === 200) {
        navigate("/all-employees/employees-list");
        toast.success("Successfully a new employee has been added");
      }
    } catch (error) {
      if (error?.response?.data?.message !== "Bad credentials") {
        toast.error(error?.response?.data?.message?.split(":")[1]);
      }
    }
  };

  const editSave = async (values) => {
    let mergedValues;

    try {
      mergedValues = {
        ...values,

        supervisor: { empId: values?.supervisor?.empId },

        employeeDob: convertDateFormat(values?.employeeDob),
        joiningDate: convertDateFormat(values?.joiningDate),
        lastWorkingDate: convertDateFormat(values?.lastWorkingDate),
      };

      const response = await axiosService.put(
        `${ALL_EMPLOYEES_URL.UPDATE_EMPLOYEE_DATA}`,
        mergedValues
      );

      if (response.status === 200) {
        navigate("/all-employees/employees-list");
        toast.success("Updated successfully");
      }
    } catch (error) {
      console.error(error?.response, "response::");
      if (error?.response?.data?.message !== "Bad credentials") {
        toast.error(error?.response?.data?.message?.split(":")[1]);
      }
    }
  };

  return (
    <div className=" employee-list-container " ref={containerRef}>
      <div className="employee-list-banner ">
        <div
          className="employee-list-table-holder pt-0 mt-0 w-100"
          style={{
            marginTop: "0",
            borderTop: "0",
            borderRadius: "0px 0px 10px 10px",
          }}
        >
          <div className="Empoyee-profile-container w-100 ">
            <div className="addEmployee-details  w-100">
              <Formik
                onSubmit={type === "Edit" ? editSave : newSave}
                initialValues={values}
              // validationSchema={validateFields}
              >
                {() => (
                  <Form>
                    <div className="row mb-3">
                      <div className="col-lg-2 col-md-3 Employee-document-filename">
                        AADHAAR
                      </div>
                      <div className="col-lg-5  col-md-7 employee-document-data">
                        <Dropzone
                          onDrop={onDrop1}
                          multiple={false}
                          maxSize={2097152}
                        >
                          {({ getRootProps, getInputProps }) => (
                            <div {...getRootProps()} className="dropzone-data">
                              <input {...getInputProps()} />

                              <div className="document-file-container">
                                <span className="document-choose">
                                  Choose file
                                </span>

                                <span className="document-image">
                                  {aadhaarImageName
                                    ? aadhaarImageName
                                    : "No file selected"}
                                </span>
                              </div>
                            </div>
                          )}
                        </Dropzone>
                      </div>
                      <div
                        className="col-lg-2 col-md-1"
                        style={{ padding: "10px 0px 0px 10px" }}
                      >
                        {aadhaarImageName &&
                          aadhaarImageName !== "No file selected" && (
                            <a
                              href={""}
                              target="_blank"
                              rel="noreferrer"
                              className="document-view-eye-icon"
                            >
                              <View />
                            </a>
                          )}
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-lg-2 col-md-3 Employee-document-filename">
                        PAN
                      </div>
                      <div className="col-lg-5 col-md-7 employee-document-data">
                        <Dropzone
                          onDrop={onDrop2}
                          multiple={false}
                          maxSize={2097152}
                        >
                          {({ getRootProps, getInputProps }) => (
                            <div {...getRootProps()} className="dropzone-data">
                              <input {...getInputProps()} />

                              <div className="document-file-container">
                                <span className="document-choose">
                                  Choose file
                                </span>

                                <span className="document-image">
                                  {panImageName
                                    ? panImageName
                                    : "No file selected"}
                                </span>
                              </div>
                            </div>
                          )}
                        </Dropzone>
                      </div>
                      <div
                        className="col-lg-2 col-md-1"
                        style={{ padding: "10px 0px 0px 10px" }}
                      >
                        {panImageName &&
                          panImageName !== "No file selected" && (
                            <a
                              href={""}
                              target="_blank"
                              rel="noreferrer"
                              className="document-view-eye-icon"
                            >
                              <View />
                            </a>
                          )}
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-lg-2 col-md-3 Employee-document-filename">
                        BANK PASSBOOK
                      </div>
                      <div className="col-lg-5 col-md-7 employee-document-data">
                        <Dropzone
                          onDrop={onDrop3}
                          multiple={false}
                          maxSize={2097152}
                        >
                          {({ getRootProps, getInputProps }) => (
                            <div {...getRootProps()} className="dropzone-data">
                              <input {...getInputProps()} />

                              <div className="document-file-container">
                                <span className="document-choose">
                                  Choose file
                                </span>

                                <span className="document-image">
                                  {bankPassBookImageName
                                    ? bankPassBookImageName
                                    : "No file selected"}
                                </span>
                              </div>
                            </div>
                          )}
                        </Dropzone>
                      </div>
                      <div
                        className="col-lg-2 col-md-1"
                        style={{ padding: "10px 0px 0px 10px" }}
                      >
                        {bankPassBookImageName &&
                          bankPassBookImageName !== "No file selected" && (
                            <a
                              href={""}
                              target="_blank"
                              rel="noreferrer"
                              className="document-view-eye-icon"
                            >
                              <View />
                            </a>
                          )}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-2 col-md-3 Employee-document-filename">
                        PHOTO
                      </div>
                      <div className="col-lg-5 col-md-7 employee-document-data">
                        <Dropzone
                          onDrop={onDrop4}
                          multiple={false}
                          maxSize={2097152}
                        >
                          {({ getRootProps, getInputProps }) => (
                            <div {...getRootProps()} className="dropzone-data">
                              <input {...getInputProps()} />

                              <div className="document-file-container">
                                <span className="document-choose">
                                  Choose file
                                </span>

                                <span className="document-image">
                                  {profileImageName
                                    ? profileImageName
                                    : "No file selected"}
                                </span>
                              </div>
                            </div>
                          )}
                        </Dropzone>
                      </div>
                      <div
                        className="col-lg-2 col-md-1"
                        style={{ padding: "10px 0px 0px 10px" }}
                      >
                        {profileImageName &&
                          profileImageName !== "No file selected" && (
                            <a
                              href={""}
                              target="_blank"
                              rel="noreferrer"
                              className="document-view-eye-icon"
                            >
                              <View />
                            </a>
                          )}
                      </div>
                    </div>

                    {/*----------- Save and update button ----------------*/}
                    <div
                      className="add-Employeepage-buttons"
                      style={{ textAlign: "right", marginTop: "20px" }}
                    >
                      {type !== "View" && (
                        <button
                          className="Cancel-btn"
                          type="button"
                          onClick={() => setModalOpen(true)}
                        >
                          Cancel
                        </button>
                      )}
                      {type === "View" && (
                        <button
                          className="Cancel-btn"
                          type="button"
                          onClick={() =>
                            navigate("/all-employees/employees-list")
                          }
                        >
                          Cancel
                        </button>
                      )}
                      {modalOpen && (
                        <CancelModal
                          modalOpen={modalOpen}
                          closeModal={() => setModalOpen(false)}
                          onSubmit={() =>
                            navigate("/all-employees/employees-list")
                          }
                          cancelButton={true}
                          submitButton={true}
                          onCloseTitle={"Cancel"}
                        />
                      )}
                      {type === "Add" || (type !== "View" && type !== "Edit") ? (
                        <button
                          className="add-button-prev"
                          type="button"
                          onClick={() => setActiveTab("Asset")}
                        >
                          Previous
                        </button>
                      ) : null}
                      {type === "Edit" ? (
                        <button type="editSubmit" className="add-button-next">
                          Update
                        </button>
                      ) : type === "View" ? (
                        ""
                      ) : (
                        <button type="submit" className="add-button-next">
                          Save
                        </button>
                      )}

                      {/* <button type="submit" className="add-button-next">
                        Save
                      </button> */}
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EmployeesDocuments;
