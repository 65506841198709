
import { AppConfig } from "../../../env.development";
 
export const ALL_EMPLOYEES_URL = {
  

  GET_ALL_EMPLOYEES: `${AppConfig.api_url}employee/v1/pagination-filter`,
  GET_ALL_EMPLOYEES_FILTER: `${AppConfig.api_url}employee/v2`,
  ADD_EMPLOYEE: `${AppConfig.api_url}employee/v1`,
  GET_IFSC_CODE:`${AppConfig.api_url}bank-detail/v1`,
  GET_ALL_EMPLOYEES_NAME: `${AppConfig.api_url}employee/v1`,
  ADD_EMPLOYEE_DATA:`${AppConfig.api_url}employee/v1`,
  UPDATE_EMPLOYEE_DATA:`${AppConfig.api_url}employee/v1`,
  GET_EMPLOYEES_ASSET: `${AppConfig.api_url}asset/v1/get-assets`,
  GET_EMPLOYEES_ASSET_DROP: `${AppConfig.api_url}asset/v1`

  
};