import { Field, Form, Formik } from "formik";
import { useState } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import Cactus from "../../../../assets/images-png/Cactus.png.png";
import WorkImage from "../../../../assets/images-png/LoginWork-image.png";
import DigiByteText from "../../../../assets/images-svg/digibytelogotext.svg";
import "../../../../core/auth/components/login/Login.css";
import { axiosService } from "../../../../services/axios.service";
import sessionService from "../../../../services/sessionstorage.service";
import { AUTH_URLS } from "../../constants/Auth-urls";

function Login() {
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const onSubmit = async (values, { setSubmitting }) => {
    try {
      if (!values.username || !values.password) {
        toast.error("Login required");
        return;
      }

      const response = await axiosService.post(
        `${AUTH_URLS.LOGIN_URL}`,
        values,
        {
          headers: { "Content-Type": "application/json" },
        }
      );

      if (response.status === 200) {
        const token = response.headers["authorization"];
        sessionService.setToken(token);
        sessionService.setUserData(response.data.user);
        navigate("/dashboard");
      } else {
        // toast.error("LOGIN_FAILED");
        toast.error("Login failed. Please check your credentials and try again.");
      }
      // } catch (error) {
      //   if (error?.response?.data?.message !== "Bad credentials") {
      //     toast.error("You have entered an invalid email or password");
      //   } else if (error.response) {
      //     toast.error("An error occurred. Please try again.");
      //   } else {
      //     toast.error(`Server Issue, Please Contact Admin.`);
      //   }
    } catch (error) {
      if (!error.response) {
        // Network error or server is down
        toast.error("Unable to connect to the server. Please check your internet connection or try again later.");
      } else if (error.response.status === 500) {
        // Unauthorized access
        toast.error("Server error. Please try again later.");
      } else if (error.response) {
        // Internal server error
        toast.error("You have entered an invalid email or password.");
      } else {
        toast.error("An error occurred. Please try again.");
      }

      console.error("Form submission error:", error);
    } finally {
      setSubmitting(false);
    }
  };

  const initialValues = {
    username: "",
    password: "",
  };

  return (
    <div className="container-fluid Login-banner ">
      <div className="row d-flex image-row">
        <div className="col-md-7 col-sm-12 col-11 login-card-col">
          <div className="logincard-con">
            <div className="Login-card">
              <div className="Midfin-logo d-flex">
                <div className="midfin-LogoFont">
                  <img
                    src={DigiByteText}
                    alt="Logo"
                    className="Midfin-png-text"
                    style={{ width: "175px" }}
                  />
                </div>
              </div>
              <div className="welcome-text">Welcome Back</div>
              <div className="loginhere-text">Please login here</div>
              <Formik
                initialValues={initialValues}
                onSubmit={onSubmit}
                enableReinitialize={true}
              >
                <Form className="loginform-container">
                  <div className="login-inputs">
                    <label htmlFor="" className="Login-Label">
                      User Name
                    </label>
                    <Field className="login-inputBox" name="username"></Field>
                  </div>
                  <div className="login-inputs">
                    <div className="d-flex ">
                      <label htmlFor="" className="Login-Label">
                        Password
                      </label>
                      <span
                        className="PasswordHide-text d-flex ms-auto"
                        onClick={handleShowPassword}
                      >
                        <span className="hide-Icon me-1">
                          {showPassword ? <FaEye /> : <FaEyeSlash />}
                        </span>
                        Hide{" "}
                      </span>
                    </div>
                    <Field
                      className="login-inputBox"
                      name="password"
                      type={showPassword ? "text" : "password"}
                    />
                  </div>
                  <Link className="forget-password-text" to="forgot-password">
                    I forgot my password
                  </Link>
                  <button type="submit" className="Login-button">
                    Login
                  </button>
                </Form>
              </Formik>
            </div>
          </div>
        </div>
        <div className="col-md-5 position-relative d-none d-md-block p-0">
          <div
            className="loginimage-background me-0"
            style={{ height: "100%" }}
          ></div>
          <div className="work-with-laptop-image position-absolute ">
            <img src={Cactus} alt="Cactus" className="Cactus-image" />
          </div>
          <div className="work-with-laptop-image position-absolute ">
            <img src={WorkImage} alt="Laptop" className="WorkingLaptopImage" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
